<ec-shared-spinner [open]="started"></ec-shared-spinner>
<div class="mb-4 flex bg-gray-100 rounded-2xl border p-4">
  <div class="flex flex-1">
    <ec-shared-icon
      class="cursor-pointer mt-2 mr-4"
      (click)="back()"
      name="chevron-left"
    ></ec-shared-icon>
    <ec-shared-icon
      *ngIf="currentView === MigrationCustomerView.VIEW && showEditButton"
      class="cursor-pointer w-6 h-6 mt-2"
      (click)="currentView = MigrationCustomerView.EDIT"
      name="pencil-square"
    ></ec-shared-icon>
    <ec-shared-icon
      *ngIf="currentView === MigrationCustomerView.EDIT"
      [solid]="true"
      class="cursor-pointer w-6 h-6 mt-2"
      (click)="currentView = MigrationCustomerView.VIEW"
      name="pencil-square"
    ></ec-shared-icon>
  </div>
  <div class="flex flex-1 justify-end">
    <empro-button
      fill="solid"
      id="start-migration-button"
      (click)="startMigrationClicked()"
      onKeyDown="onKeyDown()"
      [type]="'submit'"
    >
      <empro-typography type="small" weight="bold"
        >Migration starten</empro-typography
      >
    </empro-button>
  </div>
</div>
<div *ngIf="messageShow" id="migrationCustomerDetailsAlertBox">
  <ec-shared-alert-box
    *ngIf="messageShow"
    class="mb-4"
    [variant]="messageType"
    [message]="message"
  ></ec-shared-alert-box>
</div>
<iwt-estatecloud-migration-edit-customer
  *ngIf="currentView !== MigrationCustomerView.VIEW"
  [customer]="customerEditItem"
  (changeMigrationCustomerEvent)="changedMigrationCustomer($event)"
></iwt-estatecloud-migration-edit-customer>
