import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { groupBy, sortBy } from 'lodash';
import { EstateSmartCustomerResponse } from '../../../../models/estate-smart-customer-response';
import { MigrationCustomersResponse } from '../../../../models/migration-customers-response';
import { MigrationFilters } from '../../../../models/migration-filters';
import { MigrationSourceType } from '../../../../models/migration-source-type';
import { Sort } from '../../../../models/sort';
import { SortingSymbol } from '../../../../models/sorting-symbols';
import { SessionStorageService } from '../../../../services/session-storage-service';
import { SmartImporterService } from '../../../../services/smart-importer.service';
import { originalOrder } from '../../../common/utils';

@Component({
  selector: 'iwt-estatecloud-migration-list-customer',
  templateUrl: './migration-list-customer.component.html',
  styleUrls: ['./migration-list-customer.component.scss'],
})
export class MigrationListCustomerComponent implements OnInit {
  @Output() selectedDbNameEvent = new EventEmitter<string | undefined>();
  originalOrder = originalOrder;
  sortingSymbol = SortingSymbol;
  migrationSourceType = MigrationSourceType.ESTATE_SMART;
  filter: MigrationFilters = {};
  offset = 0;
  loading = false;
  customersWithFirstUser: EstateSmartCustomerResponse[] = [];
  displayCustomers: EstateSmartCustomerResponse[] = [];
  currentSort: Sort = {
    sortProperty: 'companyName',
    sort: SortingSymbol.ascending,
  };
  PAGE_LIMIT_KEY = 'PAGE_LIMIT_KEY';
  COLUMNS: Record<string, string> = {
    companyName: 'Firmenname',
    address: 'Straße, PLZ, Ort',
    userName: 'Benutzername',
    customerId: 'Kundennummer',
    mail: 'E-Mail',
    dbName: 'DB-Name',
  };

  constructor(
    private storage: SessionStorageService,
    private smartImporterService: SmartImporterService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.migrationSourceType !== MigrationSourceType.ESTATE_SMART) return;
    await this.loadData();
    await this.paginate();
  }

  async loadData() {
    this.loading = true;
    try {
      const customers: MigrationCustomersResponse =
        await this.smartImporterService.paginateCustomers(
          this.filter,
          this.currentSort,
          this.offset,
          0,
        );

      this.storage.saveMigrationEstateSmartCustomers(customers);
      this.displayCustomers = this.groupByDbName(customers.data);
    } catch (error) {
      // Do Nothing
    } finally {
      this.loading = false;
    }
  }

  async onFilterChange(filter: MigrationFilters) {
    this.filter = filter;
    this.offset = 0;
    await this.paginate();
  }

  onSortChange = async (sortProperty: string) => {
    if (this.currentSort.sortProperty === sortProperty) {
      this.currentSort.sort =
        this.currentSort.sort === SortingSymbol.ascending
          ? SortingSymbol.descending
          : SortingSymbol.ascending;
    } else {
      this.currentSort.sortProperty = sortProperty;
      this.currentSort.sort = SortingSymbol.ascending;
    }
    this.offset = 0;
    await this.paginate();
  };

  navigate(url: string, event: MouseEvent) {
    if (event.view?.getSelection()?.toString()) {
      return;
    }

    this.selectedDbNameEvent.emit(url);
  }

  async paginate(): Promise<void> {
    this.loading = true;
    const data = this.filterData();
    try {
      this.sortData(data);
      this.loading = false;
    } catch (error) {
      // TODO: Error Handling e.g. validation error
      // Do Nothing
    } finally {
      this.loading = false;
    }
  }

  sortDataByProperty(
    workData: EstateSmartCustomerResponse[],
    property: string,
    direction: SortingSymbol,
  ) {
    return workData.sort((x, y) => {
      const customerDbProperty = this.getDbPropertyName(property);
      return direction === SortingSymbol.descending
        ? (y[customerDbProperty]?.toString() ?? '').localeCompare(
            x[customerDbProperty]?.toString() ?? '',
          )
        : (x[customerDbProperty]?.toString() ?? '').localeCompare(
            y[customerDbProperty]?.toString() ?? '',
          );
    });
  }

  sortData(workData: EstateSmartCustomerResponse[]) {
    const { sortProperty, sort } = this.currentSort;
    if (Object.keys(this.COLUMNS).includes(sortProperty))
      this.displayCustomers = this.sortDataByProperty(
        workData,
        sortProperty,
        sort,
      );
  }

  filterData(): EstateSmartCustomerResponse[] {
    let data = this.getCustomerFromCache();
    if (!data) return [];

    const areAllNotSet = Object.values(this.filter).every(
      (x) => x === null || x === '',
    );
    if (areAllNotSet) return data;

    if (this.filter.companyName)
      data = data.filter(
        (x) =>
          x.company
            ?.toLowerCase()
            .includes(this.filter.companyName?.toLowerCase() ?? ''),
      );
    if (this.filter.userName)
      data = data.filter(
        (x) =>
          x.userName
            ?.toLowerCase()
            .includes(this.filter.userName?.toLowerCase() ?? ''),
      );
    if (this.filter.customerId)
      data = data.filter(
        (x) =>
          x.iwVerwKdNr
            ?.toString()
            .toLowerCase()
            .includes(this.filter.customerId?.toLowerCase() ?? ''),
      );
    return data;
  }

  private groupByDbName(data: EstateSmartCustomerResponse[]) {
    const dataGb = groupBy(
      sortBy(data, (x) => x.id),
      (x) => x.dbName,
    );
    return Object.values(dataGb).map((x) => x[0]);
  }

  getCustomerFromCache(): EstateSmartCustomerResponse[] {
    return this.groupByDbName(
      this.storage.getMigrationEstateSmartCustomers()?.data ?? [],
    );
  }

  getDbPropertyName(property: string) {
    switch (property) {
      case 'companyName':
        return 'company';
      case 'address':
        return 'street';
      case 'userName':
        return 'userName';
      case 'customerId':
        return 'iwVerwKdNr';
      case 'mail':
        return 'email';
      case 'dbName':
        return 'dbName';
      default:
        return 'dbName';
    }
  }
}
