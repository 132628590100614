import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CommonCustomerView } from '../../../models/common-customer-view';

@Component({
  selector: 'iwt-estatecloud-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateCustomerComponent {
  isLoading = false;
  CommonCustomerView = CommonCustomerView;

  constructor(private router: Router) {}

  async onCancelAction() {
    await this.router.navigate(['/customers']);
  }
}
