import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'iwt-estatecloud-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  constructor(
    public authService: AuthService,
    private router: Router,
  ) {}

  async onNewCustomerClicked() {
    await this.router.navigate(['/customers'], { queryParams: { new: '' } });
  }

  async onNewLicenseClicked() {
    await this.router.navigate(['/license']);
  }
}
