<iwt-estatecloud-main-layout>
  <div>
    <iwt-estatecloud-migration-header />
    <iwt-estatecloud-migration-list-customer
      *ngIf="!selectedDbName"
      (selectedDbNameEvent)="addSelectedDbName($event)"
    />
    <iwt-estatecloud-migration-customer-header
      *ngIf="selectedDbName"
      (unselectedDbNameEvent)="removeSelectedDbName()"
      [dbName]="selectedDbName"
    ></iwt-estatecloud-migration-customer-header>
    <iwt-estatecloud-migration-customer-details
      *ngIf="selectedDbName"
      [dbName]="selectedDbName"
    ></iwt-estatecloud-migration-customer-details>
  </div>
</iwt-estatecloud-main-layout>
