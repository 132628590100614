<!-- Select type -->
<ng-container *ngIf="inputType === 'select'; else checkboxType">
  <div class="input">
    <label
      #labelElement
      [id]="controlName + '-label'"
      class="text-gray-500"
      [ngClass]="isValid() ? '' : ' text-rose-500'"
      >{{ label }}</label
    >
    <select
      class="disabled:opacity-100"
      [ngClass]="{
        'bg-gray-100 rounded': form?.get(controlName ?? '')?.disabled
      }"
      (ngModelChange)="
        handleInitialTransition($event, inputElement, labelElement)
      "
      (focus)="handleTransition(inputElement, labelElement, true)"
      (focusout)="handleTransition(inputElement, labelElement, false)"
      [id]="controlName"
      [formControlName]="controlName"
      #inputElement
    >
      <ng-content />
    </select>
  </div>
</ng-container>

<!-- Checkbox Type -->
<ng-template #checkboxType>
  <ng-container *ngIf="inputType === 'checkbox'; else commonType">
    <div class="flex items-center h-full">
      <input
        formControlName="isDomestic"
        id="isDomestic"
        type="checkbox"
        value=""
        class="w-7 h-7 text-cyan-600 border-gray-300 rounded focus:ring-0 self-center"
      />
      <label for="isDomestic" class="pl-4 text-black">Testaccount</label>
    </div>
  </ng-container>
</ng-template>

<!-- Common Types -->
<ng-template #commonType>
  <div
    class="input"
    [ngClass]="{
      'bg-gray-100 rounded': form?.get(controlName ?? '')?.disabled
    }"
  >
    <label
      for="username"
      [id]="controlName + '-label'"
      [ngClass]="isValid() ? 'text-gray-500' : 'text-rose-500'"
      #labelElement
      >{{ label }}</label
    >
    <input
      [formControlName]="controlName"
      [id]="controlName"
      [pattern]="pattern"
      autocomplete="off"
      [maxlength]="maxlength"
      [type]="inputType"
      (focus)="handleTransition(inputElement, labelElement, true)"
      (focusout)="handleTransition(inputElement, labelElement, false)"
      (ngModelChange)="
        handleInitialTransition($event, inputElement, labelElement)
      "
      [ngClass]="
        isValid()
          ? 'border border-gray-400'
          : 'border-2 border-rose-500 text-rose-500'
      "
      #inputElement
    />
  </div>
</ng-template>
