import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'iwt-estatecloud-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnChanges {
  @Input() offset!: number;
  @Input() limit!: number;
  @Input() totalCount!: number;
  @Input() totalPages!: number;
  @Output() currentPageChanged = new EventEmitter<number>();
  @Output() pageLimitChanged = new EventEmitter<number>();

  currentPage: number = 1;
  pages: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const offsetChange = changes['offset'];
    if (offsetChange) {
      this.currentPage = offsetChange.currentValue / this.limit + 1;
    }
    this.paginatePages();
  }

  private paginatePages() {
    if (!this.currentPage || !this.totalPages) {
      return;
    }

    if (this.totalPages <= 5) {
      this.setPages(1, this.totalPages);
      return;
    }

    // current page near start
    if (this.currentPage <= 3) {
      this.setPages(1, 4);
      return;
    }

    // current page near end
    if (this.currentPage >= this.totalPages - 2) {
      this.setPages(this.totalPages - 3, 4);
      return;
    }

    this.setPages(this.currentPage - 1, 3);
  }

  private setPages(startPage: number, length: number) {
    this.pages = [...Array(length).keys()].map((i) => startPage + i);
  }

  setPage(page: number): void {
    if (page < 1 || page > this.totalPages || page === this.currentPage) {
      return;
    }
    this.currentPageChanged.emit(page);
  }

  setLimit(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const limit = parseInt(selectElement.value, 10);
    this.pageLimitChanged.emit(limit);
  }

  setCurrentPaginationClasses(pageNumber: number) {
    return {
      'bg-cyan-700 hover:bg-cyan-700 text-white':
        pageNumber === this.currentPage,
    };
  }
}
