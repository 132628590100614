import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomersResponse } from '../../../models/customers-response';
import { Filters } from '../../../models/filters';
import { Sort } from '../../../models/sort';
import { SortingSymbol } from '../../../models/sorting-symbols';
import { AdminService } from '../../../services/admin.service';
import { truncateString } from '../../../utils/truncate';
import { originalOrder } from '../../common/utils';

@Component({
  selector: 'iwt-estatecloud-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.css'],
})
export class ListCustomerComponent implements OnInit {
  public originalOrder = originalOrder;
  public PAGE_LIMIT_KEY = 'PAGE_LIMIT_KEY';
  public COLUMNS: Record<string, string> = {
    contractId: 'Kundennummer',
    companyName: 'Firmenname',
    tenantUuid: 'TenantUuid',
    customerId: 'LizenzierungsID',
    serviceVersion: 'Serviceversion',
    edition: 'Edition',
    editionConfig: 'EditionKonfig',
    isDomestic: 'Testaccount',
  };
  public SortingSymbol = SortingSymbol;

  public customers: CustomersResponse = { data: [], totalCount: 0 };
  public offset = 0;
  public limit = this.getPageLimitFromStorage();
  public loading = false;
  public filter: Filters = {};
  public currentSort: Sort = {
    sortProperty: 'companyName',
    sort: SortingSymbol.ascending,
  };

  constructor(
    private adminService: AdminService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    void this.paginate();
  }

  private getPageLimitFromStorage(): number {
    const pageLimit = localStorage.getItem(this.PAGE_LIMIT_KEY);

    return pageLimit === null ? 50 : parseInt(pageLimit);
  }

  navigate(url: string, event: MouseEvent) {
    if (event.view?.getSelection()?.toString()) {
      return;
    }
    void window.open('/customers/' + url, '_blank');
  }

  async paginate(): Promise<void> {
    this.loading = true;
    try {
      this.customers = await this.adminService.paginateCustomers(
        this.filter,
        this.currentSort,
        this.offset,
        this.limit,
      );
    } catch (error) {
      // TODO: Error Handling e.g. validation error
      // Do Nothing
    } finally {
      this.loading = false;
    }
  }

  get totalPages(): number {
    return Math.ceil(this.customers.totalCount / this.limit);
  }

  onPageChange(newPage: number) {
    this.offset = (newPage - 1) * this.limit;
    void this.paginate();
  }

  onLimitPageChange(pageLimit: number) {
    this.limit = pageLimit;
    this.offset = 0;
    void this.paginate();
    localStorage.setItem(this.PAGE_LIMIT_KEY, pageLimit.toString());
  }

  onFilterChange(filter: Filters) {
    this.filter = filter;
    this.offset = 0;
    void this.paginate();
  }

  onResetForm(filter: Filters) {
    this.filter = filter;
  }

  // sorting
  changeSort = (sortProperty: string) => {
    if (this.currentSort.sortProperty === sortProperty) {
      this.currentSort.sort =
        this.currentSort.sort === SortingSymbol.ascending
          ? SortingSymbol.descending
          : SortingSymbol.ascending;
    } else {
      this.currentSort.sortProperty = sortProperty;
      this.currentSort.sort = SortingSymbol.ascending;
    }
    this.offset = 0;
    void this.paginate();
  };

  truncateString(text: string | null | undefined): string {
    return truncateString(text, 100);
  }
}
