import { Injectable } from '@angular/core';
import { Filters } from '../models/filters';
import { MigrationConfigRequest } from '../models/migration-config-request';
import {
  MigrationConfigResponse,
  MigrationCustomersResponse,
} from '../models/migration-customers-response';
import { Sort } from '../models/sort';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class SmartImporterService {
  constructor(
    private envService: EnvironmentService,
    private authService: AuthService,
  ) {}

  private async getRequestInit(
    method: 'GET' | 'POST' | 'PATCH',
    body?: string,
  ): Promise<RequestInit> {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await this.authService.getToken()),
      },
      method: method,
      body: JSON.stringify(body),
    };
  }

  public async paginateCustomers(
    filter: Filters,
    sort?: Sort,
    offset: number = 0,
    limit: number = 50,
  ): Promise<MigrationCustomersResponse> {
    const config = this.envService.getConfig();
    const sortParam =
      sort === undefined ? '' : `&sort=${sort.sort}${sort.sortProperty}`;

    let query = `${config.smartImporterUrl}/estatesmart-customer?offset=${offset}&limit=${limit}${sortParam}`;

    Object.entries(filter)
      .filter(
        ([, value]) => value !== undefined && value !== null && value !== '',
      )
      .forEach(([key, value]) => {
        query += `&${key}=${encodeURIComponent(value)}`;
      });

    const token = await this.authService.getToken();
    const res = await fetch(query, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    if (!res.ok) {
      throw new Error(res.status + ' ' + res.statusText);
    }
    return await res.json();
  }

  public async createConfig(
    migrationConfig: MigrationConfigRequest,
  ): Promise<MigrationConfigResponse> {
    const envConfig = this.envService.getConfig();

    const url = `${envConfig.smartImporterUrl}/migration-config/${migrationConfig?.target.tenantUuid}`;

    const token = await this.authService.getToken();
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        ...migrationConfig,
      }),
    });
    if (!res.ok) {
      throw new Error(res.status + ' ' + res.statusText);
    }
    return await res.json();
  }

  public async startMigration(
    tenantUuid: string,
    migrationType: string,
  ): Promise<boolean> {
    if (!tenantUuid) throw new Error('tenantUuid is required');

    const envConfig = this.envService.getConfig();

    const url = `${
      envConfig.smartImporterUrl
    }/migration-start/${tenantUuid.toLowerCase()}/${migrationType}`;

    const token = await this.authService.getToken();
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    if (!res.ok) {
      console.error(res);
      return false;
    }

    return true;
  }
}
