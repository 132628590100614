export const copyToClipboard: (text: string) => void = (text: string): void => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Text erfolgreich in die Zwischenablage kopiert');
      },
      (err) => {
        console.error('Fehler beim Kopieren in die Zwischenablage', err);
      },
    );
  }
};
