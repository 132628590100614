import { Injectable } from '@angular/core';
import { EstateSmartCustomerResponse } from '../models/estate-smart-customer-response';
import { MigrationCustomersResponse } from '../models/migration-customers-response';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  public migrationEstateSmartCustomersStorageKey =
    'estate-smart-customers-response';

  public saveMigrationEstateSmartCustomers(value: MigrationCustomersResponse) {
    sessionStorage.setItem(
      this.migrationEstateSmartCustomersStorageKey,
      JSON.stringify(value),
    );
  }

  public getMigrationEstateSmartCustomers():
    | MigrationCustomersResponse
    | undefined {
    const storedString = sessionStorage.getItem(
      this.migrationEstateSmartCustomersStorageKey,
    );
    return storedString ? JSON.parse(storedString) : undefined;
  }

  public getMigrationEstateSmartCustomerByDbName(
    dbName: string | undefined,
  ): EstateSmartCustomerResponse | undefined {
    if (!dbName) return undefined;

    const storedString = sessionStorage.getItem(
      this.migrationEstateSmartCustomersStorageKey,
    );
    if (!storedString) return undefined;
    const data: MigrationCustomersResponse = JSON.parse(storedString);

    const filteredData = data.data.filter((item) => item.dbName === dbName);
    if (!filteredData) return undefined;

    return filteredData.reduce(
      (prev, curr) =>
        ((prev && prev.id) ?? Infinity) < ((curr && curr.id) ?? Infinity)
          ? prev
          : curr,
      {} as EstateSmartCustomerResponse,
    ); // Initial value
  }

  public removeMigrationEstateSmartCustomers() {
    sessionStorage.removeItem(this.migrationEstateSmartCustomersStorageKey);
  }
}
