<div *ngIf="totalCount > 0" class="flex items-center justify-between">
  <div class="sm:flex sm:flex-1 sm:items-center sm:justify-between">
    <div class="relative">
      <span class="font-medium inline-block mr-4"
        >{{ totalCount }} Ergebnisse</span
      >
      <label
        class="mr-4 absolute from-stone-300 text-xs inline-block ml-3 text-gray-500 iwt-inline-label"
        data-te-select-label-ref
        >Zeige</label
      >
      <select
        data-te-select-init
        class="cursor-pointer pt-4 border-neutral-300"
        (change)="setLimit($event)"
      >
        <option value="10" [selected]="10 === limit">10</option>
        <option value="25" [selected]="25 === limit">25</option>
        <option value="50" [selected]="50 === limit">50</option>
      </select>
    </div>
    <div>
      <nav
        class="isolate inline-flex -space-x-px rounded-md shadow-sm bg-white"
        aria-label="Pagination"
      >
        <button
          (click)="setPage(currentPage - 1)"
          [disabled]="currentPage === 1"
          class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-white focus:z-20 focus:outline-offset-0"
        >
          <span class="sr-only">Previous</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <ng-container *ngIf="!pages.includes(1)">
          <button
            class="cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            (click)="setPage(1)"
            (keyup.enter)="setPage(1)"
            tabindex="0"
          >
            1
          </button>
          <span class="text-gray-500 px-4 py-2 inline-flex items-center text-sm"
            >...</span
          >
        </ng-container>
        <button
          *ngFor="let page of pages"
          aria-current="page"
          (click)="setPage(page)"
          [ngClass]="setCurrentPaginationClasses(page)"
          class="cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
        >
          {{ page }}
        </button>
        <button
          (click)="setPage(currentPage + 1)"
          [disabled]="currentPage === totalPages"
          [ngClass]="{ 'cursor-pointer': currentPage < totalPages }"
          class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-white focus:z-20 focus:outline-offset-0"
        >
          <span class="sr-only">Next</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </nav>
    </div>
  </div>
</div>
