import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'iwt-estatecloud-migration-estate-smart',
  templateUrl: './migration-estateSmart.component.html',
  styleUrls: ['./migration-estateSmart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MigrationEstateSmartComponent {
  selectedDbName: string | undefined;

  addSelectedDbName(dbName: string | undefined) {
    this.selectedDbName = dbName;
  }
  removeSelectedDbName() {
    this.selectedDbName = undefined;
  }
}
