<div>
  <div class="relative">
    <div
      class="p-4 bg-white rounded-2xl border grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
    >
      <div class="relative" *ngIf="customerInfo.company">
        <p class="text-sm text-gray-500">Firmenname</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{ customerInfo.company }}
        </p>
      </div>
      <div class="relative" *ngIf="customerInfo.city">
        <p class="text-sm text-gray-500">Adresse</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{ customerInfo.street }} {{ customerInfo.zip }}
          {{ customerInfo.city }}
        </p>
      </div>
      <div class="relative" *ngIf="customerInfo.iwVerwKdNr">
        <p class="text-sm text-gray-500">Kundennummer</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{ customerInfo.iwVerwKdNr }}
        </p>
      </div>
      <div class="relative" *ngIf="customerInfo.dbName">
        <p class="text-sm text-gray-500">DB Name</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{ customerInfo.dbName }}
        </p>
      </div>
    </div>
  </div>

  <div class="py-2">
    <table
      SUMMARY="List of users"
      class="min-w-full text-left text-sm font-light bg-white rounded-t-2xl"
    >
      <thead class="border-b font-medium whitespace-nowrap">
        <th
          *ngFor="let column of COLUMNS | keyvalue: originalOrder"
          scope="col"
          class="px-6 py-4"
        >
          <div class="flex items-center">{{ column.value }}</div>
        </th>
      </thead>
      <tbody>
        <tr class="border-t border-gray-300" *ngFor="let customer of sortBy()">
          <td class="whitespace-nowrap py-4 pl-4 pr-3">
            {{ customer.userName }}
          </td>
          <td class="whitespace-nowrap px-6 py-4">
            {{ customer.lastName }}
          </td>
          <td class="whitespace-nowrap px-6 py-4">
            {{ customer.firstName }}
          </td>
          <td class="whitespace-nowrap px-6 py-4">
            {{ customer.email }}
          </td>
          <td class="whitespace-nowrap px-6 py-4">
            {{ customer.phone }}
          </td>
          <td class="whitespace-nowrap px-6 py-4">
            {{ customer.lastLoginDate | date: 'dd.MM.yyyy HH:mm' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
