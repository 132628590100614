<div class="antialiased h-full">
  <ec-shared-action-bar viewport="small"></ec-shared-action-bar>
  <ec-shared-navigation viewport="large">
    <a routerLink="/customers" class="flex grow md:grow-0 basis-0">
      <ec-shared-navigation-item
        icon="users"
        label="Kunden"
        routerLinkActive="active"
        viewport="large"
      ></ec-shared-navigation-item>
    </a>
    <a class="flex grow md:grow-0 basis-0" (click)="onNewCustomerClicked()">
      <ec-shared-navigation-item
        icon="plus"
        label="Neu"
        routerLinkActive="active"
        viewport="large"
      ></ec-shared-navigation-item>
    </a>
    <a routerLink="/migration" class="flex grow md:grow-0 basis-0">
      <ec-shared-navigation-item
        icon="rocket-launch"
        label="Migration"
        routerLinkActive="active"
        viewport="large"
      ></ec-shared-navigation-item>
    </a>
    <a routerLink="/license" class="flex grow md:grow-0 basis-0">
      <ec-shared-navigation-item
        icon="key"
        label="Lizenz"
        routerLinkActive="active"
        viewport="large"
      ></ec-shared-navigation-item>
    </a>
    <hr
      class="mt-auto mb-4 w-[4.75rem] border-t border-solid border-gray-400 inline-block"
    />
    <ec-shared-navigation-item
      icon="arrow-left-on-rectangle"
      label="Logout"
      class="mb-0 md:!mb-10"
      (click)="authService.logout()"
      viewport="large"
    ></ec-shared-navigation-item>
  </ec-shared-navigation>

  <main class="mt-14 md:mt-0 md:ml-48 relative">
    <div class="relative h-full p-4">
      <ng-content />
    </div>
  </main>
</div>
