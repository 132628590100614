export function handleDarkmode() {
  const darkmode = document.cookie
    .split('; ')
    .find((e) => e.includes('darkmode'));

  let darkmodeActive = false;
  if (darkmode) {
    darkmodeActive = /true/.test(darkmode.replace('darkmode=', ''));
  }
  darkmodeActive
    ? document.getElementsByTagName('html')[0].classList.add('dark')
    : document.getElementsByTagName('html')[0].classList.remove('dark');
  return darkmodeActive;
}
