<div class="mb-5 position-relative">
  <div class="relative mb-4" (keyup.enter)="searchFields()">
    <div class="flex justify-center rounded-2xl p-6 border">
      <div class="flex">
        <div class="flex gap-2 items-center justify-center">
          <form
            class="flex gap-2 items-center justify-center"
            [formGroup]="filterFormGroup"
          >
            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="contractId"
              class="input-filter"
              id="contractId"
              label="Kundennummer"
            ></iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="tenantUuid"
              class="input-filter"
              id="tenantUuid"
              label="TenantUuid"
            ></iwt-estatecloud-input>
          </form>
          <button class="iwt-yellow-button" (click)="searchFields()">
            Suchen
          </button>
          <!--Search icon-->
          <span
            class="iwt-estatecloud-top-4 z-10 input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-neutral-700 dark:text-neutral-200"
            id="basic-addon2"
          >
            <span
              (click)="toggleFilterModal()"
              class="justify-end cursor-pointer opacity-70 hover:opacity-100"
            >
              <ec-shared-icon name="adjustments-horizontal" />
            </span>
          </span>
        </div>
      </div>
    </div>
    <div
      class="overflow-hidden rounded-2xl border mb-8 mt-6"
      *ngIf="openExtendedFilter"
    >
      <div class="relative bg-white dark:bg-slate-800 p-4">
        <h2
          class="mb-2 mt-0 text-2xl font-medium leading-tight text-primary dark:text-white"
        >
          Erweiterter Filter
        </h2>
        <form [formGroup]="filterFormGroup">
          <div class="grid grid-cols-2 gap-6 md:grid-cols-3 mb-4">
            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="companyName"
              id="companyName"
              label="Firmenname"
            ></iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="customerId"
              id="customerId"
              label="LizenzierungsID"
            ></iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="serviceVersion"
              id="serviceVersion"
              label="ServiceVersion"
            ></iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="edition"
              id="edition"
              label="Edition"
              inputType="select"
            >
              <option selected></option>
              <option value="Pro" id="Pro">Pro</option>
              <option value="Office" id="Office">Office</option>
              <option value="Standalone" id="Standalone">Standalone</option>
            </iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="editionConfig"
              id="editionConfig"
              label="EditionConfig"
              inputType="select"
            >
              <option selected></option>
              <option value="Companion" id="Companion">Companion</option>
              <option value="XS" id="XS">XS</option>
              <option value="XS-Lexware" id="XS-Lexware">XS-Lexware</option>
              <option value="S" id="S">S</option>
            </iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="isDomestic"
              id="isDomestic"
              label="Testaccount"
              inputType="select"
            >
              <option selected></option>
              <option value="true" id="true">true</option>
              <option value="false" id="false">false</option>
            </iwt-estatecloud-input>
          </div>
        </form>
        <button
          id="test-cancel-btn"
          (click)="resetForm()"
          class="mt-8 text-buttons-font bg-transparent focus:ring-4 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm w-full md:w-auto text-center dark:bg-buttons-grey dark:text-white dark:hover:bg-transparent dark:focus:ring-transparent hover:underline"
        >
          Filter Zurücksetzen
        </button>
      </div>
    </div>
  </div>
</div>
