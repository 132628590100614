<iwt-estatecloud-main-layout>
  <div>
    <div class="mb-4 flex bg-gray-100 rounded-2xl border p-4">
      <ec-shared-icon
        class="cursor-pointer w-6 h-6 mr-4"
        (click)="back()"
        name="chevron-left"
      ></ec-shared-icon>
      <ec-shared-icon
        *ngIf="
          currentView === CommonCustomerView.VIEW &&
          !isLoading &&
          showEditButton
        "
        class="cursor-pointer w-6 h-6"
        (click)="currentView = CommonCustomerView.EDIT"
        name="pencil-square"
      ></ec-shared-icon>

      <ec-shared-icon
        *ngIf="currentView === CommonCustomerView.EDIT"
        [solid]="true"
        class="cursor-pointer w-6 h-6"
        (click)="currentView = CommonCustomerView.VIEW"
        name="pencil-square"
      ></ec-shared-icon>

      <ec-shared-icon
        *ngIf="
          currentView === CommonCustomerView.VIEW && showDeleteAccountButton
        "
        class="cursor-pointer w-6 h-6"
        (click)="showDeleteModal()"
        name="trash"
      ></ec-shared-icon>

      <p class="ml-4" [title]="customer?.companyName">
        {{ truncateString(customer?.companyName) }}
      </p>
    </div>

    <iwt-estatecloud-common-customer
      *ngIf="currentView !== CommonCustomerView.VIEW"
      [(isLoading)]="isLoading"
      [showForm]="showForm"
      [errorMessage]="errorMessage"
      [customer]="customer"
      [view]="currentView"
      (cancelAction)="currentView = CommonCustomerView.VIEW"
      (updateAction)="customer = $event"
    ></iwt-estatecloud-common-customer>

    <!-- currentView === "VIEW" detail page -->
    <div class="relative" *ngIf="currentView === CommonCustomerView.VIEW">
      <ec-shared-spinner [open]="isLoading"></ec-shared-spinner>
      <div
        class="p-4 bg-white rounded-2xl border grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
      >
        <ec-shared-alert-box
          *ngIf="errorMessage.show"
          class="w-full col-span-4"
          variant="error"
          header="Error"
          [message]="errorMessage.message"
        ></ec-shared-alert-box>
        <div class="relative" *ngIf="customer?.tenantUuid">
          <p class="text-sm text-gray-500">TenantUuid</p>
          <p class="text-sm flex items-center justify-between break-words">
            <span
              (click)="copyToClipboard(customer?.tenantUuid)"
              (keydown)="onKeyDown($event)"
            >
              {{ customer?.tenantUuid }}
            </span>
          </p>
        </div>
        <div class="relative" *ngIf="customer?.serviceVersion">
          <p class="text-sm text-gray-500">ServiceVersion</p>
          <p class="text-sm flex items-center justify-between break-words">
            {{ customer?.serviceVersion }}
          </p>
        </div>
        <div class="relative" *ngIf="customer?.contractId">
          <p class="text-sm text-gray-500">Kundennummer</p>
          <p class="text-sm flex items-center justify-between break-words">
            <span
              (click)="copyToClipboard(customer?.contractId)"
              (keydown)="onKeyDown($event)"
            >
              {{ customer?.contractId }}
            </span>
          </p>
        </div>
        <div class="relative" *ngIf="customer?.customerId">
          <p class="text-sm text-gray-500">LizenzierungsID</p>
          <p class="text-sm flex items-center justify-between break-words">
            <span
              (click)="copyToClipboard(customer?.customerId)"
              (keydown)="onKeyDown($event)"
            >
              {{ customer?.customerId }}
            </span>
          </p>
        </div>
        <div class="relative" *ngIf="customer?.licenseCount">
          <p class="text-sm text-gray-500">Lizenzanzahl</p>
          <p class="text-sm flex items-center justify-between break-words">
            {{ customer?.licenseCount }}
          </p>
        </div>
        <div class="relative" *ngIf="customer?.companyName">
          <p class="text-sm text-gray-500">Firmenname</p>
          <p class="text-sm flex items-center justify-between break-words">
            <span
              (click)="copyToClipboard(customer?.companyName)"
              (keydown)="onKeyDown($event)"
            >
              {{ customer?.companyName }}
            </span>
          </p>
        </div>
        <div class="relative" *ngIf="customer?.edition">
          <p class="text-sm text-gray-500">Edition</p>
          <p class="text-sm flex items-center justify-between break-words">
            {{ customer?.edition }}
          </p>
        </div>
        <div class="relative" *ngIf="customer?.editionConfig">
          <p class="text-sm text-gray-500">EditionConfig</p>
          <p class="text-sm flex items-center justify-between break-words">
            {{ customer?.editionConfig }}
          </p>
        </div>
        <div class="relative" *ngIf="customer?.isDomestic">
          <p class="text-sm text-gray-500">Testaccount</p>
          <p class="text-sm flex items-center justify-between break-words">
            {{ customer?.isDomestic }}
          </p>
        </div>
      </div>
    </div>

    <div
      *ngIf="migrationStatusResponse?.timestamp"
      class="p-4 mt-2 bg-white rounded-2xl border grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-4"
    >
      <div class="relative" *ngIf="customer?.tenantUuid">
        <p class="text-sm text-gray-500">Migration Quelle</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{ migrationStatusResponse?.migrationType }}
        </p>
      </div>
      <div class="relative" *ngIf="customer?.tenantUuid">
        <p class="text-sm text-gray-500">Migration gestartet</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{ migrationStatusResponse?.timestamp | date: 'dd.MM.yyyy HH:mm' }}
        </p>
      </div>
      <div class="relative" *ngIf="customer?.tenantUuid">
        <p class="text-sm text-gray-500">Migration beended</p>
        <p class="text-sm flex items-center justify-between break-words">
          {{
            migrationStatusResponse?.statusTimestamp | date: 'dd.MM.yyyy HH:mm'
          }}
        </p>
      </div>
      <div class="relative" *ngIf="customer?.tenantUuid">
        <p class="text-sm text-gray-500">Status</p>
        <div class="w-20">
          <empro-shared-badge
            [variant]="getBadgeVariant()"
            [hover]="false"
            [hasBorder]="false"
            [rounded]="16"
            [size]="'small'"
            [shadow]="'small'"
            >{{ migrationStatusResponse?.executionStatus }}</empro-shared-badge
          >
        </div>
      </div>
      <div class="w-full relative">
        <empro-button
          class="absolute inset-y-0 right-0 font-medium"
          [isLoading]="isLoading"
          [disabled]="migrationStatusResponse?.executionStatus !== 'SUCCESS'"
          [fill]="'outline'"
          size="small"
          [hidden]="isLoading"
          (click)="deleteSupportUserClick()"
          (keydown)="onKeyDown($event)"
        >
          <empro-typography type="small" weight="bold">
            Zugangsdaten an Kunden versenden
          </empro-typography>
        </empro-button>
      </div>
    </div>

    <iwt-estatecloud-list-user
      [users]="users"
      [tenantUuid]="customer?.tenantUuid"
    ></iwt-estatecloud-list-user>
  </div>

  <ec-shared-modal
    [open]="openDeleteAccountModal"
    [hasClose]="true"
    [closeOutside]="false"
    (onClose)="closeModal()"
  >
    <ng-container>
      <div modalTitle>
        <empro-shared-typography
          type="h6"
          [classes]="'inline-flex text-subtle'"
          title
        >
          <div class="default-styling">Kunde löschen</div>
        </empro-shared-typography>
      </div>
    </ng-container>
    <div modalContent class="text-left" [ngClass]="'h-full'">
      <div class="mt-4">
        <div>
          <empro-shared-typography [classes]="'inline-flex text-subtle'" title>
            <p>
              <span class="default-styling">
                Bis du sicher, dass der Account
                {{ this.customer?.companyName?.substring(0, 50) }} gelöscht
                werden soll?
              </span>
            </p>
          </empro-shared-typography>
        </div>
        <br />
        <hr />
        <div class="flex justify-center items-center gap-x-10 mt-6" modalFooter>
          <empro-button
            [variant]="'secondary'"
            (click)="closeModal('delete')"
            [fill]="'solid'"
            class="font-medium"
            onKeyDown="onKeyDown()"
          >
            Löschen
          </empro-button>
          <empro-button
            [variant]="'primary'"
            (click)="closeModal()"
            [fill]="'solid'"
            class="font-medium"
            onKeyDown="onKeyDown()"
          >
            Abbrechen
          </empro-button>
        </div>
      </div>
    </div>
  </ec-shared-modal>
</iwt-estatecloud-main-layout>
