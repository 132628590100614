import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivateChild = this.canActivate;

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const isAuthorized = await this.authService.isAuthorized();
    if (route.url.join('/') === 'login') {
      return isAuthorized ? this.router.parseUrl('/customers') : true;
    }

    return isAuthorized === true ? true : this.router.parseUrl('/login');
  }
}
