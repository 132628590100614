import { Injectable } from '@angular/core';
import { LegacyLicenseFreeCodeRequest } from '../models/license';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';

export interface Address {
  street?: string;
  zipCode?: string;
  city?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  constructor(
    private authService: AuthService,
    private environmentService: EnvironmentService,
  ) {}

  async createLicense(
    licenseData: LegacyLicenseFreeCodeRequest,
  ): Promise<string> {
    const body = this.createBody(licenseData);
    const init: RequestInit = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await this.authService.getToken()),
      },
    };

    const res = await fetch(
      `${this.environmentService.getConfig().legacyLicenseFreeCodeUrl}`,
      init,
    );

    if (res.ok) {
      const txt = await res.text();
      if (!txt.indexOf('<BuildUnlockCodeResult>')) return '';
      return this.extractBuildUnlockCodeResult(txt);
    }

    if (!res.ok) {
      const responseJson = await res.json();
      const message = responseJson.message;
      let errorMessage = '';

      if (typeof message === 'string') {
        errorMessage = JSON.parse(responseJson.message)?.endUserMessage;
      }

      if (message instanceof Array) {
        errorMessage = message.join(', ');
      }

      throw new Error(responseJson.statusCode + ' - ' + errorMessage);
    }

    return '';
  }

  createBody(
    licenseData: LegacyLicenseFreeCodeRequest,
  ): LegacyLicenseFreeCodeRequest {
    return {
      hWSerial: licenseData.hWSerial,
      hexCustomerId: licenseData.hexCustomerId,
      expirationDate: licenseData.expirationDate,
      maxUserLimit: licenseData.maxUserLimit,
      commonProductId: licenseData.commonProductId,
      functionCode: licenseData.functionCode,
      notes: licenseData.notes,
    };
  }

  extractBuildUnlockCodeResult(xml: string): string {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'application/xml');
    const resultElement = xmlDoc.getElementsByTagName(
      'BuildUnlockCodeResult',
    )[0];

    return resultElement?.textContent ?? 'Kein Freischaltcode erzeugt.';
  }
}
