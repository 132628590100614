import { Component, Input, OnInit } from '@angular/core';
import { EstateSmartCustomerResponse } from '../../../../models/estate-smart-customer-response';
import { SessionStorageService } from '../../../../services/session-storage-service';
import { originalOrder } from '../../../common/utils';

@Component({
  selector: 'iwt-estatecloud-migration-customer-details',
  templateUrl: './migration-customer-details.component.html',
  styleUrls: ['./migration-customer-details.component.scss'],
})
export class MigrationCustomerDetailsComponent implements OnInit {
  @Input() dbName: string | undefined;
  customers: EstateSmartCustomerResponse[] = [];
  customerInfo: EstateSmartCustomerResponse = {} as EstateSmartCustomerResponse;
  public originalOrder = originalOrder;
  public COLUMNS: Record<string, string> = {
    userName: 'Benutzername',
    lastName: 'Nachname',
    firstName: 'Vorname',
    mail: 'E-Mail',
    phone: 'Telefon',
    loginDate: 'Letzter Login',
  };

  constructor(private storage: SessionStorageService) {}

  ngOnInit(): void {
    this.loadCustomers();
  }

  loadCustomers() {
    if (!this.dbName) return;

    const response = this.storage.getMigrationEstateSmartCustomers();
    if (!response) return;

    this.customers = response.data.filter((x) => x.dbName === this.dbName);
    this.customerInfo = this.sortCustomers();
  }

  sortCustomers() {
    const customers = this.customers.slice().sort((a, b) => {
      return (a.id ?? 0) - (b.id ?? 0);
    });
    return customers[0];
  }

  sortBy() {
    return this.customers.sort((a, b) =>
      (a.id ?? 0) > (b.id ?? 0) ? 1 : (a.id ?? 0) === (b.id ?? 0) ? 0 : -1,
    );
  }
}
