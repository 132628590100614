import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  FunctionCode,
  LegacyLicenseFreeCodeRequest,
  Options,
  RadioTypes,
} from '../../../models/license';
import { LicenseService } from '../../../services/license.service';
import { copyToClipboard } from '../../../utils/clipboard';

/**
 * Represents the LicenseComponent class.
 */
@Component({
  selector: 'iwt-estatecloud-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent {
  commonProductIdOptions: Options[] = this.getProductOptions().sort((a, b) =>
    a.version > b.version ? a.label.localeCompare(b.label) : -1,
  );

  formGroup = new FormGroup({
    commonProductId: new FormControl(76, [Validators.required]),
    licenseType: new FormControl('single', [Validators.required]),
    acquisitionType: new FormControl('buy', [Validators.required]),
    maxUserLimit: new FormControl(1, [Validators.required]),
    expirationDate: new FormControl(''),
    hexCustomerId: new FormControl('', [Validators.required]),
    hWSerial1: new FormControl('', [Validators.required]),
    hWSerial2: new FormControl('', [Validators.required]),
    hWSerial3: new FormControl('', [Validators.required]),
    freeCode1: new FormControl(''),
    freeCode2: new FormControl(''),
    freeCode3: new FormControl(''),
    freeCode4: new FormControl(''),
    notes: new FormControl(''),
  });

  functionCode: FunctionCode | number = FunctionCode.SINGLE_BUY;
  freeCodeInputs: string[] = [
    'freeCode1',
    'freeCode2',
    'freeCode3',
    'freeCode4',
  ];
  freeCode = '';
  response = '';

  hardwareSerialInputs: string[] = ['hWSerial1', 'hWSerial2', 'hWSerial3'];
  acquisitionType: string | null | undefined = 'buy';

  constructor(
    private router: Router,
    private service: LicenseService,
  ) {
    this.updateAcquisitionType();
  }

  /**
   * Get product options
   * @returns Options[]
   */
  getProductOptions(): Options[] {
    return [
      { version: 1.6, label: 'EstatePro 1.6', value: 16 },
      { version: 1.6, label: 'EstateOffice 1.6', value: 14 },
      { version: 1.6, label: 'EstateOffice Laptop 1.6', value: 15 },
      { version: 1.6, label: 'EstatePro Laptop 1.6', value: 13 },
      { version: 2.0, label: 'EstatePro 2.0', value: 18 },
      { version: 2.0, label: 'EstateOffice 2.0', value: 20 },
      { version: 2.0, label: 'EstateOffice Laptop 2.0', value: 17 },
      { version: 2.0, label: 'EstatePro Laptop 2.0', value: 19 },
      { version: 3.0, label: 'EstatePro 3.0', value: 22 },
      { version: 3.0, label: 'EstateOffice 3.0', value: 24 },
      { version: 3.0, label: 'EstateOffice Laptop 3.0', value: 23 },
      { version: 3.0, label: 'EstatePro Laptop 3.0', value: 21 },
      { version: 4.0, label: 'EstatePro 4.0', value: 28 },
      { version: 4.0, label: 'EstateOffice 4.0', value: 26 },
      { version: 4.0, label: 'EstateOffice Laptop 4.0', value: 25 },
      { version: 4.0, label: 'EstatePro Laptop 4.0', value: 27 },
      { version: 5.0, label: 'EstatePro 5.0', value: 30 },
      { version: 5.0, label: 'EstateOffice 5.0', value: 32 },
      { version: 5.0, label: 'EstateOffice Laptop 5.0', value: 31 },
      { version: 5.0, label: 'EstatePro Laptop 5.0', value: 29 },
      { version: 6.0, label: 'EstatePro 6.0', value: 36 },
      { version: 6.0, label: 'EstateOffice 6.0', value: 34 },
      { version: 6.0, label: 'EstateOffice Laptop 6.0', value: 33 },
      { version: 6.0, label: 'EstatePro Laptop 6.0', value: 35 },
      { version: 6.6, label: 'EstatePro 6.2', value: 42 },
      { version: 6.6, label: 'EstateOffice 6.2', value: 44 },
      { version: 6.6, label: 'EstateOffice Laptop 6.2', value: 41 },
      { version: 6.6, label: 'EstatePro Laptop 6.2', value: 43 },
      { version: 7.0, label: 'EstatePro 7.0', value: 38 },
      { version: 7.0, label: 'EstateOffice 7.0', value: 40 },
      { version: 7.0, label: 'EstateOffice Laptop 7.0', value: 37 },
      { version: 7.0, label: 'EstatePro Laptop 7.0', value: 39 },
      { version: 7.1, label: 'EstatePro 7.1', value: 48 },
      { version: 7.1, label: 'EstateOffice 7.1', value: 46 },
      { version: 7.1, label: 'EstateOffice Laptop 7.1', value: 47 },
      { version: 7.1, label: 'EstatePro Laptop 7.1', value: 45 },
      { version: 7.2, label: 'EstatePro 7.2', value: 50 },
      { version: 7.2, label: 'EstateOffice 7.2', value: 52 },
      { version: 7.2, label: 'EstateOffice Laptop 7.2', value: 49 },
      { version: 7.2, label: 'EstatePro Laptop 7.2', value: 51 },
      { version: 8.0, label: 'EstatePro 8.0', value: 54 },
      { version: 8.0, label: 'EstateOffice 8.0', value: 56 },
      { version: 8.0, label: 'EstateOffice Laptop 8.0', value: 53 },
      { version: 8.0, label: 'EstatePro Laptop 8.0', value: 55 },
      { version: 8.1, label: 'EstatePro 8.1', value: 60 },
      { version: 8.1, label: 'EstateOffice 8.1', value: 58 },
      { version: 8.1, label: 'EstateOffice Laptop 8.1', value: 59 },
      { version: 8.1, label: 'EstatePro Laptop 8.1', value: 57 },
      { version: 9.0, label: 'EstatePro 9.0', value: 62 },
      { version: 9.0, label: 'EstateOffice 9.0', value: 64 },
      { version: 9.0, label: 'EstateOffice Laptop 9.0', value: 63 },
      { version: 9.0, label: 'EstatePro Laptop 9.0', value: 61 },
      { version: 9.1, label: 'EstatePro 9.1', value: 68 },
      { version: 9.1, label: 'EstateOffice 9.1', value: 66 },
      { version: 9.1, label: 'EstateOffice Laptop 9.1', value: 65 },
      { version: 9.1, label: 'EstatePro Laptop 9.1', value: 67 },
      { version: 9.2, label: 'EstatePro 9.2', value: 70 },
      { version: 9.2, label: 'EstateOffice 9.2', value: 72 },
      { version: 9.2, label: 'EstateOffice Laptop 9.2', value: 69 },
      { version: 9.2, label: 'EstatePro Laptop 9.2', value: 71 },
      { version: 10.0, label: 'EstatePro 10.0', value: 76 },
      { version: 10.0, label: 'EstateOffice 10.0', value: 74 },
      { version: 10.0, label: 'EstateOffice Laptop 10.0', value: 75 },
      { version: 10.0, label: 'EstatePro Laptop 10.0', value: 73 },
      // future products
      // { version: 10.1, label: 'EstatePro 10.1', value: 78 },
      // { version: 10.1, label: 'EstateOffice 10.1', value: 80 },
      // { version: 10.1, label: 'EstateOffice Laptop 10.1', value: 77 },
      // { version: 10.1, label: 'EstatePro Laptop 10.1', value: 79 },
      // { version: 10.2, label: 'EstatePro 10.2', value: 82 },
      // { version: 10.2, label: 'EstateOffice 10.2', value: 84 },
      // { version: 10.2, label: 'EstateOffice Laptop 10.2', value: 83 },
      // { version: 10.2, label: 'EstatePro Laptop 10.2', value: 81 },
    ];
  }

  /**
   * Get license type radios
   * @returns RadioTypes[]
   */
  getLicenseTypeRadios(): RadioTypes[] {
    return [
      {
        label: 'Einzelplatz (pro Arbeitsplatz)',
        value: 'single',
        id: 'singleWorkplace',
        control: this.formGroup.get('licenseType'),
      },
      {
        label: 'Firmenlizenz Anzahl:',
        value: 'company',
        id: 'companyWorkplace',
        control: this.formGroup.get('licenseType'),
        countControl: this.formGroup.get('maxUserLimit'),
      },
      {
        label: 'Demo (Verlängerung)',
        value: 'demo-extension',
        id: 'demo-extension',
        control: this.formGroup.get('licenseType'),
      },
      {
        label: 'Reset Einzelplatz (Achtung)',
        value: 'reset-single',
        id: 'resetSingle',
        control: this.formGroup.get('licenseType'),
      },
      {
        label: 'Reset Volumenlizenz (Achtung)',
        value: 'reset-volume',
        id: 'resetVolume',
        control: this.formGroup.get('licenseType'),
      },
    ];
  }

  /**
   * Get acquisition type radios
   * @returns RadioTypes[]
   */
  getAcquisitionTypeRadios(): RadioTypes[] {
    return [
      {
        label: 'Kauf',
        value: 'buy',
        id: 'buy',
        control: this.formGroup.get('acquisitionType'),
      },
      {
        label: 'Miete',
        value: 'rent',
        id: 'rent',
        control: this.formGroup.get('acquisitionType'),
      },
    ];
  }

  /**
   * Submit form
   * @returns Promise<void>
   */
  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.setFunctionCode();
      await this.sendData();
    }
  }

  /**
   * Set free code to form inputs
   * @param freeCode - free code to set
   * @returns void
   */
  setFreecode(freeCode: string = ''): void {
    const fg = this.formGroup;
    this.freeCode = freeCode;
    const freeCodeArray = freeCode.split(' ');
    if (freeCodeArray.length === 4) {
      this.freeCodeInputs.forEach((field, index) => {
        fg.get(field)?.setValue(freeCodeArray[index]);
      });
      copyToClipboard(freeCode.replace(/ /g, ''));
    }
  }

  /**
   * Check if hardware serial code has errors
   * @returns boolean
   */
  checkHardwareSerialCode(): boolean {
    return this.hardwareSerialInputs.some((field) => {
      const control = this.formGroup.get(field);
      return control?.touched && control?.errors;
    });
  }

  /**
   * Check if button should be disabled
   * @returns
   */
  buttonDisabled(): boolean {
    return this.formGroup.invalid || this.checkHardwareSerialCode();
  }

  /**
   * Copy free code to clipboard
   * @returns void
   */
  copyFreecode(): void {
    const fg = this.formGroup;
    copyToClipboard(
      `${fg.get('freeCode1')?.value}${fg.get('freeCode2')?.value}${fg.get(
        'freeCode3',
      )?.value}${fg.get('freeCode4')?.value}`,
    );
  }

  /**
   * Set function code based on license type and acquisition type
   * @returns void
   */
  setFunctionCode(): void {
    const licenseType = this.formGroup.get('licenseType')?.value ?? '';
    const acquisitionType = this.formGroup.get('acquisitionType')?.value;

    const functionCodeMap: Record<string, FunctionCode> = {
      'single-buy': FunctionCode.SINGLE_BUY,
      'company-buy': FunctionCode.COMPANY_BUY,
      'single-rent': FunctionCode.SINGLE_RENT,
      'company-rent': FunctionCode.COMPANY_RENT,
      'demo-extension': FunctionCode.DEMO_EXTENSION,
      'reset-single': FunctionCode.RESET,
      'reset-volume': FunctionCode.RESET,
    };

    this.functionCode =
      functionCodeMap[`${licenseType}-${acquisitionType}`] ??
      functionCodeMap[licenseType];
  }

  /**
   * Send data to server
   * @returns Promise<void>
   */
  async sendData(): Promise<void> {
    this.response = '';
    const data: LegacyLicenseFreeCodeRequest = this.extractFormProperties();
    if (data.expirationDate.length == 0)
      data.expirationDate = '0001-01-01T00:00:00';

    try {
      this.response = await this.service.createLicense(data);
    } catch (e) {
      this.setFreecode('Kein Freischaltcode erzeugt.');
      return;
    }

    if (this.response?.length === 16) {
      const displayFreecode = this.response.match(/.{1,4}/g)?.join(' ') ?? '';
      this.setFreecode(displayFreecode);
    } else {
      this.setFreecode(this.response);
    }
  }

  extractFormProperties(): LegacyLicenseFreeCodeRequest {
    let limit = 0;
    if (
      this.formGroup.get('maxUserLimit') &&
      this.formGroup.get('maxUserLimit')!.value
    )
      limit = +(this.formGroup.get('maxUserLimit')?.value ?? 1);

    if (
      this.functionCode === FunctionCode.SINGLE_BUY ||
      this.functionCode === FunctionCode.SINGLE_RENT ||
      this.functionCode === FunctionCode.RESET ||
      this.functionCode === FunctionCode.DEMO_EXTENSION
    )
      limit = 0;

    return {
      hWSerial: `${this.formGroup.get('hWSerial1')?.value} ${this.formGroup.get(
        'hWSerial2',
      )?.value} ${this.formGroup.get('hWSerial3')?.value}`,
      hexCustomerId: this.formGroup.get('hexCustomerId')?.value ?? '',
      expirationDate: this.formGroup.get('expirationDate')?.value ?? '',
      maxUserLimit: limit,
      commonProductId: this.formGroup.get('commonProductId')?.value ?? 76,
      notes: this.formGroup.get('notes')?.value ?? '',
      functionCode: this.functionCode,
    };
  }

  updateAcquisitionType() {
    this.formGroup
      .get('licenseType')
      ?.valueChanges.subscribe((value: string | null | undefined) => {
        if (
          value === 'demo-extension' ||
          value === 'reset-volume' ||
          value === 'reset-single'
        ) {
          if (this.formGroup.get('acquisitionType')?.enabled)
            this.acquisitionType = this.formGroup.get('acquisitionType')?.value;
          this.formGroup.get('acquisitionType')?.disable();
          this.formGroup.get('acquisitionType')?.setValue(null);
        } else {
          if (this.formGroup.get('acquisitionType')?.disabled)
            this.formGroup
              .get('acquisitionType')
              ?.setValue(this.acquisitionType ?? null);
          this.formGroup.get('acquisitionType')?.enable();
        }
      });
  }

  /**
   * Cancel and navigate to home
   */
  async cancel(): Promise<void> {
    await this.router.navigate(['/']);
  }

  onKeyDown() {
    console.log('key down'); // sonar issue Mouse events should have corresponding keyboard events
  }
}
