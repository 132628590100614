<section class="bg-gray-50 dark:bg-gray-900">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0"
  >
    <a
      class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
    >
      estatePro Admin-Tool
    </a>
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <p
          *ngIf="errorMessage"
          class="text-sm text-red-700 dark:text-red-400 w-full text-center"
        >
          {{ errorMessage }}
        </p>
        <form class="space-y-4 md:space-y-6" action="#">
          <button
            (click)="login()"
            type="submit"
            class="w-full text-black bg-yellow-300 hover:opacity-80 focus:ring-transparent focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Login via Microsoft
          </button>
        </form>
      </div>
    </div>
    <div class="flex justify-center flex-wrap text-sky-600 mt-4">
      <a
        href="https://www.immowelt-software.de/"
        target="_blank"
        rel="noopener"
        class="mx-2 mb-6"
        >immowelt-Software</a
      >
      <a
        href="https://www.immowelt-software.de/kontakt"
        target="_blank"
        rel="noopener"
        class="mx-2 mb-6"
        >Kontakt</a
      >
      <a [href]="privacyUrl" target="_blank" rel="noopener" class="mx-2 mb-6"
        >Datenschutz</a
      >
      <a [href]="imprintUrl" target="_blank" rel="noopener" class="mx-2 mb-6"
        >Impressum</a
      >
    </div>
  </div>
</section>
