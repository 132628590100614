import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { CustomerDetailComponent } from './pages/customer-detail/customer-detail.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { LicenseComponent } from './pages/license/license.component';
import { LoginComponent } from './pages/login/login.component';
import { MigrationEstateSmartComponent } from './pages/migration-estateSmart/migration-estateSmart.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'license',
    component: LicenseComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customers',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        component: CustomersComponent,
      },
      {
        path: ':id',
        component: CustomerDetailComponent,
      },
    ],
  },
  {
    path: 'migration',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        component: MigrationEstateSmartComponent,
      },
    ],
  },
  { path: '', redirectTo: '/login', pathMatch: 'prefix' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
