import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _authError = new BehaviorSubject<string | undefined>(
    undefined,
  );

  readonly authError$ = this._authError.asObservable();

  constructor(private environmentService: EnvironmentService) {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signIn_failure') {
        const errorMessage = decodeURIComponent(data.message).replace(
          /\+/g,
          ' ',
        );
        const prefix =
          'Invalid SAML response received: PreAuthentication failed with error ';
        this._authError.next(
          errorMessage.startsWith(prefix)
            ? errorMessage.slice(prefix.length)
            : errorMessage,
        );
      }
    });
  }

  async getToken(): Promise<string | undefined> {
    const isAuthorized = await this.isAuthorized();
    if (!isAuthorized) {
      return undefined;
    }
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }

  async isAuthorized(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      return false;
    }
  }

  async login() {
    const config = this.environmentService.getConfig();
    await Auth.federatedSignIn({
      customProvider: config.customProvider as string,
    });
  }

  async logout() {
    await Auth.signOut();
  }

  async isAdmin(): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.payload['cognito:groups'].includes(
      'Admin',
    );
  }
}
