<div
  class="relative bg-white dark:bg-slate-800 rounded-2xl border mb-8 overflow-visible"
>
  <ec-shared-spinner [open]="isLoading"></ec-shared-spinner>
  <div class="p-4">
    <ec-shared-alert-box
      *ngIf="errorMessage.show"
      class="mb-4"
      variant="error"
      header="Error"
      [message]="errorMessage.message"
    ></ec-shared-alert-box>

    <form [formGroup]="createCustomerForm" *ngIf="showForm">
      <div class="grid grid-cols-2 gap-6 md:grid-cols-3 mb-4">
        <!-- create customer fields -->
        <iwt-estatecloud-input
          [formGroup]="staticCreateForm"
          *ngIf="view !== CommonCustomerView.CREATE"
          [form]="staticCreateForm"
          controlName="tenantUuid"
          label="TenantUuid*"
        ></iwt-estatecloud-input>

        <iwt-estatecloud-input
          [formGroup]="staticCreateForm"
          *ngIf="view !== CommonCustomerView.CREATE"
          [form]="staticCreateForm"
          controlName="serviceVersion"
          label="ServiceVersion*"
        ></iwt-estatecloud-input>

        <!-- common fields -->
        <iwt-estatecloud-input
          [form]="createCustomerForm"
          pattern="[1-9][0-9]*"
          controlName="contractId"
          label="Kundennummer*"
        ></iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="createCustomerForm"
          pattern="[1-9][0-9]*"
          controlName="customerId"
          label="LizenzierungsID*"
        ></iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="createCustomerForm"
          pattern="[1-9][0-9]*"
          controlName="licenseCount"
          label="Lizenzanzahl*"
        ></iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="createCustomerForm"
          controlName="companyName"
          maxlength="256"
          label="Firmenname*"
        ></iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="createCustomerForm"
          controlName="edition"
          label="Produkt*"
          inputType="select"
        >
          <option selected disabled>Edition*</option>
          <option value="Pro" id="Pro">Pro</option>
          <option value="Office" id="Office">Office</option>
          <option value="Standalone" id="Standalone">Standalone</option>
        </iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="createCustomerForm"
          controlName="editionConfig"
          label="Produkt-Konfig*"
          inputType="select"
        >
          <option selected disabled>Produkt-Konfig*</option>
          <option *ngFor="let config of editionConfigOptions" [value]="config">
            {{ config }}
          </option>
        </iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="createCustomerForm"
          controlName="isDomestic"
          label="Testaccount"
          inputType="checkbox"
        ></iwt-estatecloud-input>
      </div>

      <div
        *ngIf="showCreateStandaloneCustomerForm"
        class="grid grid-cols-2 gap-6 md:grid-cols-3 mb-4"
        [formGroup]="createStandaloneCustomerForm"
      >
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="salutation"
          label="Anrede"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="firstName"
          label="Vorname"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="lastName"
          label="Nachname*"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="street"
          label="Straße"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="zipCode"
          label="PLZ"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="city"
          label="Ort"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="country"
          label="Land"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="businessPhone"
          label="Telefon"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="mobilePhone"
          label="Mobil"
        ></iwt-estatecloud-input>
        <iwt-estatecloud-input
          [form]="createStandaloneCustomerForm"
          controlName="email"
          label="E-Mail*"
        ></iwt-estatecloud-input>
      </div>

      <button
        (click)="save()"
        class="text-buttons-font focus:ring-4 focus:outline-none font-medium focus:ring-transparent rounded-lg text-sm w-full md:w-auto px-5 py-2.5 text-center bg-yellow-300 hover:opacity-80"
      >
        Speichern
      </button>
      <button
        (click)="cancelButtonClicked()"
        type="submit"
        class="md:ml-4 mt-4 md:mt-0 text-buttons-font bg-transparent focus:ring-4 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm w-full md:w-auto px-5 py-2.5 text-center dark:bg-buttons-grey dark:text-white dark:hover:bg-transparent dark:focus:ring-transparent hover:underline"
      >
        Abbrechen
      </button>
    </form>
  </div>
</div>
