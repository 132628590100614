import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { EnvironmentService } from '../../../services/environment.service';
import { handleDarkmode } from '../../common/darkmode';

@Component({
  selector: 'iwt-estatecloud-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  errorMessage: string | undefined = '';
  privacyUrl: string | undefined = '';
  imprintUrl: string | undefined = '';

  constructor(
    private authService: AuthService,
    environmentService: EnvironmentService,
  ) {
    const config = environmentService.getConfig();
    this.privacyUrl = config.privacyUrl;
    this.imprintUrl = config.imprintUrl;
    handleDarkmode();
  }

  ngOnInit(): void {
    this.authService.authError$.subscribe((errorMessage) => {
      if (errorMessage !== undefined) {
        this.errorMessage = errorMessage;
      }
    });
  }

  async login() {
    await this.authService.login();
  }
}
