<div class="mb-5">
  <iwt-estatecloud-search
    (filterEvent)="onFilterChange($event)"
    (resetFormEvent)="onResetForm($event)"
  />

  <div class="relative overflow-auto">
    <ec-shared-spinner [open]="loading"></ec-shared-spinner>
    <div class="border rounded-t-2xl">
      <table
        class="min-w-full text-left text-sm font-light bg-white rounded-t-2xl"
      >
        <thead class="border-b font-medium whitespace-nowrap">
          <tr>
            <th
              *ngFor="let column of COLUMNS | keyvalue: originalOrder"
              scope="col"
              class="px-6 py-4 cursor-pointer hover:bg-cyan-50"
              (click)="changeSort(column.key)"
            >
              <div class="flex items-center">
                {{ column.value }}
                <span *ngIf="currentSort.sortProperty === column.key">
                  <ec-shared-icon
                    [defaultSize]="false"
                    class="w-5 h-5 p-0.5"
                    [name]="
                      currentSort.sort === SortingSymbol.ascending
                        ? 'chevron-up'
                        : 'chevron-down'
                    "
                  />
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let customer of customers.data"
            (click)="navigate(customer.customerId, $event)"
            class="border-b hover:bg-cyan-50 cursor-pointer"
          >
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.contractId }}
            </td>
            <td
              [title]="customer.companyName"
              class="whitespace-nowrap px-6 py-4 font-medium"
            >
              {{ truncateString(customer.companyName) }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.tenantUuid }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.customerId }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.serviceVersion }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.edition }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.editionConfig }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ customer.isDomestic }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="overflow-hidden rounded-b-2xl border border-t-transparent bg-white p-4 sm:max-w-6"
    >
      <iwt-estatecloud-pagination
        *ngIf="customers"
        [offset]="offset"
        [limit]="limit"
        [totalPages]="totalPages"
        [totalCount]="customers.totalCount"
        (currentPageChanged)="onPageChange($event)"
        (pageLimitChanged)="onLimitPageChange($event)"
      />
    </div>
  </div>
</div>
