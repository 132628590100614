/* eslint-disable @typescript-eslint/no-explicit-any */
export type ResponseModel = {
  freeCode: string;
};

export type RequestModel = {
  hWSerial: string;
  hexCustomerId: string;
  expirationDate: string;
  maxUserLimit: number;
  commonProductId: number;
  notes: string;
  functionCode: number;
};

export enum FunctionCode {
  SINGLE_BUY = 2,
  COMPANY_BUY = 1,
  SINGLE_RENT = 3,
  COMPANY_RENT = 11,
  DEMO_EXTENSION = 4,
  RESET = 8,
}

export type Options = {
  label: string;
  value: number;
  version: number;
};

export type RadioTypes = {
  label: string;
  value: string;
  id: string;
  control: any;
  countControl?: any;
};

export class LegacyLicenseFreeCodeRequest {
  hWSerial!: string;
  hexCustomerId!: string;
  expirationDate!: string;
  maxUserLimit!: number;
  commonProductId!: number;
  functionCode!: number;
  notes!: string;
}
