import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { EnvironmentConfig } from '../models/environment-config';
import { scriptLoader } from '../utils/script-loader';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  config: EnvironmentConfig | undefined;

  async initConfig(): Promise<void> {
    const res = await fetch('/assets/config.json');
    this.config = await res.json();
    if (this.config === undefined) {
      throw new Error('Could not load config!');
    }
    Auth.configure(this.config.amplify);
    scriptLoader(`${this.config.sharedUrl}/shared-components.js`);
  }

  getConfig(): EnvironmentConfig {
    return this.config!;
  }
}
