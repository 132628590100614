<div class="mb-4 flex bg-gray-100 rounded-2xl border p-4">
  <ec-shared-icon
    class="cursor-pointer mr-4"
    (click)="onCancelAction()"
    name="chevron-left"
  ></ec-shared-icon>

  <p>Neuen Kunden anlegen</p>
</div>
<iwt-estatecloud-common-customer
  [view]="CommonCustomerView.CREATE"
  (cancelAction)="onCancelAction()"
></iwt-estatecloud-common-customer>
