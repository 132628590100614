import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'iwt-estatecloud-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class InputComponent {
  @Input() valid = true;
  @Input() controlName: string | null = null;
  @Input() maxlength: string | number | null = null;
  @Input() label = ' ';
  @Input() pattern = '';
  @Input() inputType = 'text';
  @Input() form: FormGroup | undefined;

  isValid() {
    if (this.form && this.controlName) {
      // next-line: workaround for labels
      this.form
        ?.get(this.controlName)
        ?.setValue(this.form.get(this.controlName)?.value);
      return !(
        this.form.get(this.controlName)?.touched &&
        this.form.get(this.controlName)?.invalid
      );
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isElementActive(el: any) {
    return document.activeElement === el;
  }

  handleInitialTransition(
    event: string | number | null,
    input: HTMLInputElement | HTMLSelectElement,
    label: HTMLLabelElement,
  ) {
    if (event) {
      this.handleTransition(input, label, true);
    }
  }

  handleTransition(
    input: HTMLInputElement | HTMLSelectElement,
    label: HTMLLabelElement,
    add: boolean,
  ) {
    if (add) {
      label.classList.add('-translate-y-3', 'scale-[0.83]');
    } else if (!input.value) {
      label.classList.remove('-translate-y-3', 'scale-[0.83]');
    }
  }
}
