<div class="mb-5">
  <iwt-estatecloud-migration-search-customer
    (filterEvent)="onFilterChange($event)"
  />
  <div class="relative overflow-auto">
    <ec-shared-spinner [open]="loading"></ec-shared-spinner>
    <div class="border rounded-t-2xl">
      <table
        SUMMARY="List of customers"
        class="min-w-full text-left text-sm font-light bg-white rounded-t-2xl"
      >
        <thead class="border-b font-medium whitespace-nowrap">
          <tr>
            <th
              *ngFor="let column of COLUMNS | keyvalue: originalOrder"
              scope="col"
              class="px-6 py-4 cursor-pointer hover:bg-cyan-50"
              (click)="onSortChange(column.key)"
            >
              <div class="flex items-center">
                {{ column.value }}

                <span *ngIf="currentSort.sortProperty === column.key">
                  <ec-shared-icon
                    [defaultSize]="false"
                    class="w-5 h-5 p-0.5"
                    [name]="
                      currentSort.sort === sortingSymbol.ascending
                        ? 'chevron-up'
                        : 'chevron-down'
                    "
                  />
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let field of displayCustomers"
            class="border-t border-gray-300 hover:bg-cyan-50 cursor-pointer"
            (click)="navigate(field.dbName || '', $event)"
          >
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
            >
              {{ field.company }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ field.street }} {{ field.zip }} {{ field.city }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ field.userName }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ field.iwVerwKdNr }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ field.email }}
            </td>
            <td class="whitespace-nowrap px-6 py-4">
              {{ field.dbName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
